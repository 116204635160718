import React, { useState, Fragment } from "react"
import SEO from './seo'
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default ({ children }) => (
  <div>
    <header>
      <span className="brand">
        <Link to="/">Parsec Research</Link>
      </span>
      <Link to="/about" className="pill">about</Link>
    </header>
    <div className="body-wrapper">
      {children}
    </div>
    <Footer />
  </div>
)

function Footer () {
  const [email, setEmail] = useState("")
  const [subbed, setSubbed] = useState(false)

  const submit = async () => {
    const { result, msg } = await addToMailchimp(email)
    if (result === "success") {
      setSubbed(true)
    }
  }

  return (
    <footer>
      <span className="parsec">
        <div>Parsec</div>
        <div className="footer-link">
          <OutboundLink href="https://parsec.finance" target="_blank" rel="noopener noreferrer">Website</OutboundLink>
        </div>
        <div className="footer-link">
          App <OutboundLink href="https://app.parsec.finance/signup" target="_blank" rel="noopener noreferrer"><span className="coming-soon">Free Trial</span></OutboundLink>
        </div>
      </span>
      <span className="contact">
        <div>Contact</div>
        <div className="footer-link">
          <OutboundLink href="https://twitter.com/parsec_finance" target="_blank" rel="noopener noreferrer">twitter</OutboundLink>
        </div>
        <div className="footer-link">
          <OutboundLink href="mailto:will@parsec.finance" target="_blank" rel="noopener noreferrer">email</OutboundLink>
        </div>
      </span>
    </footer>
  )
}